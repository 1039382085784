import React, { useContext } from "react"
import tw from "twin.macro"
import { OfferDetailHeroProps } from "./OfferDetailHero.d"
import { Image } from "../../../atoms/Image"
import { LanguageContext } from "../../../../contexts/Language"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { parseDisclaimerBlocks } from "../../../../helpers"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"

/**
 * Hero image of the OfferDetail page
 * @author Tyler
 *
 * @param {string} mobileBackgroundImage The background image of the hero component for mobile
 * @param {string} desktopBackgroundImage The background image of the hero component for desktop
 * @param {IGatsbyImageData} heroImage The series image displayed in the hero
 * @param {string} name The name of the series the offer applies to
 * @returns <section></section>
 */

const OfferDetailHero: React.FC<OfferDetailHeroProps> = ({
  heroBackgroundDesktop,
  heroBackgroundMobile,
  heroImage,
  name,
  type,
}) => {
  const { _ } = useContext(LanguageContext)
  //~~~~~~~~~~ Styles ~~~~~~~~~~
  const mobileBackgroundImage = `
  @media (max-width: 640px) {
    background: linear-gradient(rgba(0, 0, 0, 0) 40%, rgba(237, 240, 245, 1) 80%, rgba(0, 0, 0, 0)), url(${heroBackgroundMobile});
    min-height: 19rem;
    background-size: cover;
    background-position: center;
  }`

  const desktopBackgroundImage = `
  @media (min-width: 640px) {
    background: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(237, 240, 245, 1) 80%, rgba(0, 0, 0, 0)), url(${heroBackgroundDesktop});
    min-height: 30rem;
    background-size: cover;
    background-position: center;
  }`
  const [state, modalDispatch] = useContext(DisclaimersContext)
  return (
    <section
      css={[
        mobileBackgroundImage,
        desktopBackgroundImage,
        tw`relative flex justify-center items-end w-full`,
      ]}
      aria-label={`${name}` + " Offers Hero Section"}
    >
      <div css={[tw`absolute top-6 max-w-desktop content-center mx-auto`]}>
        <h1
          css={[
            tw`text-white text-3xl font-light pl-6`,
            tw`sm:(text-5xl pl-10 my-5 flex flex-col content-center mx-auto max-w-desktop)`,
            "word-spacing: 100vw; letter-spacing: 3.75px",
            `@media (min-width: 640px) {
                letter-spacing: 6px;
              }`,
          ]}
        >
          {parseDisclaimerBlocks(name, selection =>
            modalDispatch(toggleDisclaimersModal(selection))
          )}{" "}
          {_("Offer")}
        </h1>
      </div>
      <div css={[tw`absolute bottom-0 w-full h-[20%] bg-gray-100`]}></div>
      {heroImage && (
        <Image
          imageData={heroImage}
          css={[
            tw`relative top-8 my-auto mx-8 object-contain`,
            type === "Military"
              ? tw`w-[240px] !top-20 md:(w-[406px]) lg:(w-[506px])`
              : type === "College"
              ? tw`w-[240px] !top-20 md:(w-[406px]) lg:(w-[506px])`
              : tw`w-[864px]`,
            tw`sm:(top-10)`,
            tw`md:(top-10)`,
          ]}
        />
      )}
    </section>
  )
}

export default OfferDetailHero
