import React, { useContext } from "react"
import tw from "twin.macro"
import { LanguageContext } from "../../../contexts/Language"
import Icon from "../../atoms/Icon"
import { Image } from "../../atoms/Image"
import { Link } from "../../atoms/Link"
import { MoreAboutTileProps } from "./MoreAboutTile.d"
import { normalizeSeriesNameForLink } from "../../../helpers"

/**
 * "More About" Card for use accross website
 * @author Tyler, Stu (adapted to re-usable component)
 *
 * @param {string} name The name of the series that this card applies to
 * @param {string} to Vehicle
 * @param {IGatsbyImageData} moreAboutImage Series image for background of card
 * @param {string} alt Alt text for moreAboutImage
 * @param {boolean} padding Add padding to image if required (static value)
 * @param {boolean} margins Add margins to image if required (static value)
 * @returns {JSX.Element}
 *
 * @todo Add moreAboutImage text to sanity schema to make it dynamic
 * @todo Find a way to make padding and margins dynamic values. Use emotion/styled component and pass css prop?
 */

const MoreAboutTile: React.FC<MoreAboutTileProps> = ({
  name,
  moreAboutImage,
  padding = false,
  margins = false,
}): JSX.Element => {
  const { _ } = useContext(LanguageContext)
  // This approach used because twin macro does not do string interpolation:
  // https://github.com/ben-rogerson/twin.macro/discussions/393
  const mx = margins ? tw`mx-4` : null
  const pr = padding ? tw`lg:pr-8` : null
  // page_name uses lowercase series name, no spaces
  let seriesPath = normalizeSeriesNameForLink(name)
  if (name?.toLowerCase()?.includes("hybrid")) {
    seriesPath += "?hybrid=true"
  }

  return (
    <article
      css={[tw`grid max-w-[22.5rem] w-full`, tw`lg:(min-w-[22.5rem])`, mx, pr]}
    >
      <div
        css={[tw`col-start-1 col-end-2 row-start-1 row-end-2 px-6 py-11 z-20`]}
      >
        <div css={[tw`text-2xl leading-[1.875rem] font-semibold `]}>
          {_("Style, tech, and safety features set")} {name} {_("apart")}.
        </div>
        <Link to={`/${seriesPath}`} css={[tw`flex items-center gap-2`]}>
          <span css={[tw`text-xs leading-3 font-semibold capitalize`]}>
            {_("More About")} {name}
          </span>
          <Icon.Chevron
            direction="right"
            color="red-400"
            css={[tw`h-2.5 mt-0.5`]}
          />
        </Link>
      </div>
      <Image
        imageData={moreAboutImage.image}
        css={[tw`col-start-1 col-end-2 row-start-1 row-end-2 z-10`]}
      />
    </article>
  )
}

export default MoreAboutTile
