import React, { useContext } from "react"
import tw from "twin.macro"
import { OfferDetailDescriptionProps } from "./OfferDetailDescription.d"
import { MoreAboutTile } from "../../../molecules/MoreAboutTile"
import { LanguageContext } from "../../../../contexts/Language"

/**
 * Written text in the main body of the offer detail page.
 * @author Tyler
 *
 * @param {string} description Short description of this offer
 * @param {Object} includedModels Models that this offer applies to
 * @param {string} bullet More detailed description of this offer
 * @param {string} disclaimer Terms and conditions of offer
 * @param {string} extraDisclaimer Extra terms and conditions of offer
 * @param {string} name The name of the series that this offer applies to
 * @returns
 *
 * @todo Add moreAboutImage text to sanity schema to make it dynamic. From Stu: See MoreAboutTile component.
 */

const OfferDetailDescription: React.FC<OfferDetailDescriptionProps> = ({
  description,
  includedModels,
  bullet,
  disclaimer,
  extraDisclaimer,
  name,
  moreAboutImage,
}) => {
  const { _ } = useContext(LanguageContext)
  // Some descriptions are very long, making large font impractical
  const isLong = description?.length > 200
  const altStyles = [tw`text-sm`, tw`lg:(text-sm)`]

  return (
    <section
      css={[
        tw`flex flex-col items-center bg-white pb-8 mt-12 max-w-desktop`,
        tw`lg:(flex-row items-start gap-28 mx-auto)`,
      ]}
      aria-label="Offer Details Description"
    >
      <article css={[tw`px-8`]}>
        {description && (
          <p
            css={[
              tw`text-lg leading-6 font-semibold mb-8`,
              tw`lg:( text-3xl leading-[3rem] )`,
              isLong && altStyles,
            ]}
          >
            {description}
          </p>
        )}
        <p
          css={[
            tw`flex gap-1 flex-wrap text-sm italic mb-8`,
            tw`lg:( text-base )`,
          ]}
        >
          <span>{_("Applies to")}:</span>
          {includedModels?.length > 0 ? (
            includedModels?.map((node: { name: string }, index: number) => (
              <span>
                {includedModels?.length > 1 &&
                  index === includedModels?.length - 1 &&
                  `${_("and")} `}
                {node?.name}
                {includedModels?.length > 1 &&
                index !== includedModels?.length - 1
                  ? `,`
                  : includedModels?.length === 1
                  ? null
                  : `.`}
              </span>
            ))
          ) : (
            <span>
              {_("all")} {name}s
            </span>
          )}
        </p>
        {bullet && <p css={[tw`mb-8`, tw`lg:(text-xl)`]}>{bullet}</p>}
        {disclaimer && (
          <p
            css={[
              tw`text-xs leading-[1.125rem] mb-8`,
              extraDisclaimer && tw`mb-4`,
            ]}
          >
            <div>{_("Terms & Conditions")}</div>
            {disclaimer}
          </p>
        )}
        {extraDisclaimer && (
          <p css={[tw`text-xs leading-[1.125rem] mb-8`]}>{extraDisclaimer}</p>
        )}
      </article>

      {moreAboutImage && (
        <MoreAboutTile
          name={name}
          moreAboutImage={moreAboutImage}
          to=""
          margins
          padding
        />
      )}
    </section>
  )
}

export default OfferDetailDescription
